import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '../../../../../components/molecules/Box';
import Link from '../../../../../components/atoms/Link';
import Picture from '../../../../../components/atoms/Picture';
import Typography from '../../../../../components/atoms/Typography';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../../../../src/utils/analytics';
import { analyticsData } from '../../../../../../../src/constants/Analytics/analytics-data';
import useStyles from '../../style';
import { evalConditionForIcpLicense } from '../../../../../utils/cookie';
import { NoSsr } from '@material-ui/core';

const LogoAndCopyright = ({
  logoImage,
  logoLink,
  copyRight,
  icpLicenseNumberHeading,
  icpLicenseNumber,
  language,
  secondaryQuickLinks,
  pageName,
  componentName,
  isDetailPage,
}) => {
  const logoClickHandler = () => {
    pushAnaylyticsData(analyticsData.footerInteraction.dctLogoClick);
  };
  const getQuickLinkInfo = (fieldItem, type) => {
    return get(fieldItem, `fields.links.value.${type}`, '');
  };
  const getListItem = (item, index) => {
    return (
      <ListItem key={index} disableGutters={true} dense={false}>
        <Link
          data-id={analyticsData.dataIds.footerNavLinkClick}
          data-link={createDynamicLabels([
            pageName,
            componentName,
            `${analyticsData.dataLinksText.quickLinks} - ${getQuickLinkInfo(
              item,
              'text'
            )}`,
          ])}
          link={get(item, 'fields.links', {})}
          className={classes.secondaryLinkItem}
        >
          <Typography
            variant="body2Bold"
            className="secondaryLink"
            component="span"
          >
            {getQuickLinkInfo(item, 'text')}
          </Typography>
        </Link>
      </ListItem>
    );
  };
  const classes = useStyles();
  return (
    <>
      <Box className={clsx(classes.copyright, { detailPage: isDetailPage })}>
        <div>
          <Link link={logoLink}>
            <Picture
              onClick={() => logoClickHandler()}
              className="footer-logo"
              media={{
                image: logoImage,
                mobileImage: logoImage,
                renditionNeeded: false,
                disableLazyLoad: true,
                fallbackBackgroundNeeded: false,
              }}
              isParalax={false}
            />
          </Link>
        </div>
        <div>
          <List className={classes.secondaryLinks}>
            {secondaryQuickLinks &&
              secondaryQuickLinks.map((linkItem, index) => {
                return getListItem(linkItem, index);
              })}
          </List>
          <Typography component="p" variant="body2Light" className="copy-txt">
            {copyRight && <Text field={copyRight} />}
          </Typography>
          <NoSsr>
            {evalConditionForIcpLicense(language) && (
              <Typography component="p" variant="body2Light" className="copy-txt">
                {icpLicenseNumberHeading}{' '}
                {icpLicenseNumber && (
                  <Link link={icpLicenseNumber} className={classes.linkItem}>
                    <Typography
                      variant="body2Bold"
                      component="span"
                      className={classes.icpLicense}
                    >
                      {get(icpLicenseNumber, 'value.text')}
                    </Typography>
                  </Link>
                )}
              </Typography>
            )}
          </NoSsr>
        </div>
      </Box>
    </>
  );
};

export default LogoAndCopyright;
