import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  const { left, marginLR, right, paddingLR } = theme.mixins;
  return {
    root: {
      display: 'flex',
    },
    labelHeading: {
      ...theme.typography.body2Bold,
      marginBottom: theme.spacing(2),
    },
    formControl: {
      '& .MuiFormGroup-root': {
        '& .MuiCollapse-container .MuiFormControlLabel-root': {
          width: '100%',
        },
        '& .MuiFormControlLabel-root': {
          ...theme.mixins.marginLR(pxToRem(-11), pxToRem(16)),
        },
        '& .MuiFormControlLabel-root .MuiCheckbox-root': {
          padding: 0,
          ...paddingLR(pxToRem(11), 0),
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
          marginBottom: pxToRem(16),
          cursor: 'pointer',
        },
        '& .MuiFormControlLabel-root .MuiTypography-root': {
          ...marginLR(8, 0),
        },
        '& .MuiFormControlLabel-label.Mui-disabled': {
          color: theme.mixins.DisabledGrey(),
          '& .checked-icon': {
            background: theme.mixins.DisabledGrey(),
          },
        },
        '& .MuiFormControlLabel-label': {
          color: theme.mixins.Black(),
        },
        '& .MuiFormControlLabel-root.Mui-disabled': {
          '& $checkedIcon': {
            background: theme.mixins.Grey(400),
            borderColor: theme.mixins.Grey(400),
          },
        },
      },
    },
    icon: {
      width: pxToRem(24),
      height: pxToRem(24),
      border: theme.borders.fieldBorder,
      backgroundColor: theme.mixins.AntiqueWhite(),
      borderRadius: borderRadius.b3,
      position: 'relative',
      'input:hover ~ &': {
        border: theme.borders.redBorder,
      },
    },
    labelWrap: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& .MuiCheckbox-root': {
        padding: '0',
      },
      '& label': {
        '& p': {
          marginLeft: '8px'
        }
      }
    },
    checkedIcon: {
      border: theme.borders.redBorder,
      background: theme.mixins.AbuDhabiOrange(),
      '&:before': {
        display: 'block',
        position: 'absolute',
        top: pxToRem(7),
        ...left(pxToRem(6)),
        width: pxToRem(11),
        height: pxToRem(5),
        transform: 'rotate(-45deg)',
        border: `1px solid ${theme.mixins.White()}`,
        borderTop: 'none',
        borderRight: 'none',
        content: '""',
      },
    },
    toggleIcon: {
      ...theme.typography.body2Bold,
      position: 'relative',
      color: theme.mixins.AbuDhabiOrange(),
      cursor: 'pointer',


      '&:after': {
        content: "''",
        position: 'absolute',
        border: `solid ${theme.mixins.AbuDhabiOrange()}`,
        width: pxToRem(10),
        height: pxToRem(10),
        borderWidth: '0 2px 2px 0',
        transform: 'rotate(45deg)',
        top: pxToRem(4),
        ...right(pxToRem(-21)),
      },
      '&.less:after': {
        top: pxToRem(9),
        transform: 'rotate(227deg)',
      },
    },
  };
});

export default useStyles;
