import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Collapse from '@material-ui/core/Collapse';
import useStyles from './style';
import clsx from 'clsx';
import Typography from '../../Typography';

const CheckBoxMUI = props => {
  const classes = useStyles();
  const [shouldShowSeeMore, setShouldShowSeeMore] = React.useState(true);
  const {
    seeMoreText,
    seeLessText,
    shouldShowLimited,
    shouldShowResultsCount,
    className,
  } = props;
  const { fieldName, fieldLabel, listItems, totalResultCount } = props.data;

  const renderLabel = label => {
    return (
      <Typography variant={'body2'} gutterBottom={false}>
        <Text field={label} />
      </Typography>
    );
  };

  const renderSingleCheckbox = (
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(props.selected)}
          name={fieldName && fieldName.value}
          checkedIcon={
            <span
              className={clsx(classes.icon, classes.checkedIcon, 'checkedIcon')}
            />
          }
          onChange={props.onChange}
          value={true}
          icon={<span className={classes.icon} />}
        />
      }
      label={renderLabel(fieldLabel)}
    />
  );

  const renderCheckbox = (data, shouldShowLimited) => {
    if (shouldShowLimited) {
      let checkboxesToShow = [];
      let checkboxesToHide = [];
      for (let index in data) {
        let checkboxLabelDOM = '';
        const { displayText, key, selected, count, disabled } = data[
          index
        ].fields;
        const displayTextToShow = shouldShowResultsCount
          ? { value: `${displayText.value} (${count.value})` }
          : displayText;
        if (parseInt(index) <= 3) {
          checkboxLabelDOM = (
            <div className={classes.labelWrap}>
              <Checkbox
                checked={Boolean(selected && selected.value)}
                name={fieldName && fieldName.value}
                checkedIcon={
                  <span
                    className={clsx(
                      classes.icon,
                      classes.checkedIcon,
                      'checkedIcon'
                    )}
                  />
                }
                id={displayTextToShow.value}
                value={key && key.value}
                onChange={props.onChange}
                icon={<span className={classes.icon} />}
                disabled={disabled}
              />
              <label for={displayTextToShow.value}>{renderLabel(displayTextToShow)}</label>
            </div>
          );

          checkboxesToShow.push(checkboxLabelDOM);
        } else {
          checkboxLabelDOM = (
            <div className={classes.labelWrap}>
              <Checkbox
                checked={Boolean(selected && selected.value)}
                name={fieldName && fieldName.value}
                checkedIcon={
                  <span
                    className={clsx(
                      classes.icon,
                      classes.checkedIcon,
                      'checkedIcon'
                    )}
                  />
                }
                id={displayTextToShow.value}
                value={key && key.value}
                onChange={props.onChange}
                icon={<span className={classes.icon} />}
                disabled={disabled}
              />
              <label for={displayTextToShow.value}>{renderLabel(displayTextToShow)}</label>
            </div>
          );

          checkboxesToHide.push(checkboxLabelDOM);
        }
      }
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          handleSeeMoreClick();
        }
      };
      return (
        <React.Fragment>
          {checkboxesToShow}
          <Collapse in={!shouldShowSeeMore}>{checkboxesToHide}</Collapse>
          {checkboxesToHide && checkboxesToHide.length > 0 && (
            <div className='showlessWrapper'>
              <span
                aria-expanded={shouldShowSeeMore ? false : true}
                className={clsx(
                  classes.toggleIcon,
                  shouldShowSeeMore ? 'more' : 'less'
                )}
                onClick={() => handleSeeMoreClick()}
                tabIndex={0}
                onKeyDown={handleKeyPress}
                role="button"
              >
                {shouldShowSeeMore ? seeMoreText.value : seeLessText.value}
              </span>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      let options = data.map((item, index) => {
        const { displayText, key, selected, count, disabled } = item.fields;
        const displayTextToShow = shouldShowResultsCount
          ? { value: `${displayText.value} (${count.value})` }
          : displayText;
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(selected && selected.value)}
                name={fieldName && fieldName.value}
                checkedIcon={
                  <span
                    className={clsx(
                      classes.icon,
                      classes.checkedIcon,
                      'checkedIcon'
                    )}
                  />
                }
                value={key && key.value}
                onChange={props.onChange}
                icon={<span className={classes.icon} />}
                disabled={disabled}
              />
            }
            label={renderLabel(displayTextToShow)}
            key={index}
          />
        );
      });

      return options;
    }
  };
  const handleSeeMoreClick = () => {
    setShouldShowSeeMore(!shouldShowSeeMore);
  };
  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        className={clsx(classes.formControl, className)}
      >
        {listItems && listItems.length > 0 ? (
          <React.Fragment>
            <FormLabel className={classes.labelHeading} component="h2">
              {fieldLabel &&
                `${fieldLabel.value} ${shouldShowResultsCount ? `(${totalResultCount})` : ''
                }`}
            </FormLabel>
            <FormGroup>
              {renderCheckbox(listItems, shouldShowLimited)}
            </FormGroup>
          </React.Fragment>
        ) : (
          renderSingleCheckbox
        )}
      </FormControl>
    </div>
  );
};

export default CheckBoxMUI;
