import React from 'react';
import Divider from '@material-ui/core/Divider';
import get from 'lodash/get';
import Hidden from '@material-ui/core/Hidden';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import Box from '../../../../../components/molecules/Box';
import Link from '../../../../../components/atoms/Link';
import useStyles from '../../style';
import IconWithCircle from '../../../../../components/atoms/IconWithCircle';
import Typography from '../../../../../components/atoms/Typography';
import Picture from '../../../../../components/atoms/Picture';
import { ScrollToTop } from '../../../../../../ScrollToTop';
import { analyticsData } from '../../../../../../../src/constants/Analytics/analytics-data';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../../../utils/analytics';

const DownloadApp = ({
  isMobile,
  mobileAppLinks,
  mobileAppTitle,
  pageName,
  componentName,
}) => {
  const classes = useStyles();
  const getDownloadAppInfo = (fieldItem, type, category) => {
    return get(fieldItem, `fields.${category}.value.${type}`, '');
  };
  const scrollTopHandler = () => {
    addAnalyticsInfo();
    ScrollToTop('smooth');
  };
  const addAnalyticsInfo = () => {
    pushAnaylyticsData(analyticsData.pageBodyInteraction.backToTopClick);
  };
  return (
    <>
      <Box component="div">
        <Hidden smDown implementation="css">
          <Typography
            component="h2"
            className={classes.downloadAppHeading}
            variant="h5Bold"
            animateSettings={{
              willAnimate: false,
            }}
          >
            {mobileAppTitle && <Text field={mobileAppTitle} />}
          </Typography>
        </Hidden>

        <Box component="div" className={`${classes.downloadApp} downloadApp`}>
          <Hidden mdUp implementation="css">
            <Typography
              component="h2"
              className={classes.downloadAppHeading}
              variant="h5"
              animateSettings={{
                willAnimate: false,
              }}
            >
              {mobileAppTitle && <Text field={mobileAppTitle} />}
            </Typography>
          </Hidden>
          <div style={{ display: 'flex' }}>
            {mobileAppLinks &&
              mobileAppLinks.length > 0 &&
              mobileAppLinks.map((item, index) => {
                return (
                  <Link
                    data-id={analyticsData.dataIds.footerNavAppclick}
                    data-link={createDynamicLabels([
                      pageName,
                      componentName,
                      `${analyticsData.dataLinksText.downloadapp
                      } - ${getDownloadAppInfo(item, 'text', 'link')}`,
                    ])}
                    aria-label={getDownloadAppInfo(item, 'text', 'link')}
                    key={index}
                    className={classes.downloadAppLogo}
                    link={item.fields.link}
                  >
                    <Picture
                      media={{
                        image: item.fields.image,
                        renditionNeeded: false,
                        disableLazyLoad: true,
                        fallbackBackgroundNeeded: false,
                      }}
                      alternateAltText={mobileAppTitle && mobileAppTitle.value}
                      isParalax={false}
                    />
                  </Link>
                );
              })}
          </div>
          {!isMobile && (
            <Box
              component="div"
              onClick={scrollTopHandler}
              className={classes.ScrollToTopContainer}
            >
              <IconWithCircle
                name="ArrowUpwardIcon"
                className={classes.scrollTopHover}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box component="div" className={`${classes.divider} divider`}>
        <Divider component="div" />
      </Box>
    </>
  );
};

export default DownloadApp;
