import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  const { left, right } = theme.mixins;
  return {
    formControl: {
      marginTop: pxToRem(40),
      '& .MuiFormGroup-root': {
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
        },
        '& .MuiFormControlLabel-root': {
          '& .MuiTypography-root': {
            flex: '0 0 100%',
            maxWidth: '100%'
          }
        },
      },
      '& .rating-txt-wrap': {
        '& .ratingLabel': {
          display: 'inline-flex',
          maxWidth: 'fit-content !important',
          minWidth: '85px',
          width: '100%',
          color: '#706C6C',
          ...theme.mixins.marginRight(8),
          '@media (max-width: 1200px)': {
            minWidth: '65px',
          },
        },
        [theme.breakpoints.down('lg')]: {
          lineHeight: 0,
        },
      },
    },
    labelHeading: {
      ...theme.typography.body2Bold,
    },
    root: {

      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
    ratingUi: {
      display: 'inline',
      marginRight: pxToRem(4),
      lineHeight: 0,
      '& .rating-circle': {
        width: pxToRem(12),
        height: pxToRem(12),
        borderRadius: borderRadius.b999px,
        overflow: 'hidden',
        display: 'inline-block',
        ...theme.mixins.marginRight(pxToRem(4)),
        backgroundColor: theme.palette.common.TripAdvisorThemeColor,
        border: `1px solid ${theme.palette.common.TripAdvisorThemeColor}`,
        '&.is-empty': {
          backgroundColor: 'transparent',
        },
      },
    },
    icon: {
      borderRadius: borderRadius.circle,
      width: pxToRem(24),
      height: pxToRem(24),
      border: theme.borders.fieldBorder,
      backgroundColor: theme.mixins.AntiqueWhite(),
      position: 'relative',
      'input:hover ~ &': {
        border: theme.borders.redBorder,
      },
    },
    checkedIcon: {
      border: theme.borders.redBorder,
      '&:before': {
        display: 'block',
        position: 'absolute',
        top: pxToRem(3),
        ...left(pxToRem(3)),
        width: pxToRem(16),
        height: pxToRem(16),
        background: theme.mixins.AbuDhabiOrange(),
        border: theme.borders.redBorder,
        borderRadius: borderRadius.circle,
        content: '""',
      },
    },
    toggleIcon: {
      position: 'relative',
      color: theme.mixins.AbuDhabiOrange(),
      cursor: 'pointer',

      '&:after': {
        content: "''",
        position: 'absolute',
        border: `solid ${theme.mixins.AbuDhabiOrange()}`,
        width: pxToRem(10),
        height: pxToRem(10),
        borderWidth: '0 2px 2px 0',
        transform: 'rotate(45deg)',
        top: 4,
        ...right(pxToRem(-21)),
      },
      '&.less:after': {
        top: pxToRem(9),
        transform: 'rotate(227deg)',
      },
    },
  };
});

export default useStyles;
