import React from 'react';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import useStyles from './Radio.style';
import Typography from '../../Typography';
import { FormLabel } from '@material-ui/core';
import { getDictionaryText } from '../../../../utils/getDictionaryText';

const StyledRadio = props => {
  const classes = useStyles();

  return (
    <Radio
      className={`${classes.root} radio-btn`}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon) + ' radio-btn-Chekedicon'} />}
      icon={<span className={`${classes.icon} radio-btn-icon`} />}
      {...props}
    />
  );
};

const RatingUI = (ratingNum) => {
  const classes = useStyles();
  return (
    <span className={classes.ratingUi}>
      {[...Array(5)].map((value, i) => {
        return (
          <i key={i} className={`rating-circle ${i < ratingNum.value ? 'is-full' : 'is-empty'}`}></i>
        )
      })}
    </span>
  );
}

const renderLabel = (label, isFilterRating) => {
  let labelRating = '';
  let ratingNum = false;
  let labelText = '';
  if (isFilterRating) {
    if (label.value === 'any-rating') {
      labelRating = getDictionaryText('anyRating');
      labelText = getDictionaryText('ta_any_rating');
    } else if (label.value === 'five') {
      labelRating = '';
      ratingNum = 5;
      labelText = getDictionaryText('ta_excellent');

    } else if (label.value === 'four-and-up') {
      labelRating = getDictionaryText('andUp');
      labelText = getDictionaryText('ta_very_good');

      ratingNum = 4;
    } else if (label.value === 'three-and-up') {
      labelRating = getDictionaryText('andUp');
      ratingNum = 3;
      labelText = getDictionaryText('ta_good');
    }
  }
  return (
    <Typography variant={'body2'} component={'span'} gutterBottom={false}>
      {isFilterRating ? (
        <div className={'rating-txt-wrap'}>
          <Typography variant={'body2'} component={'span'} className='ratingLabel'>{labelText}</Typography>
          {ratingNum && (<RatingUI value={ratingNum} />)}
          {labelRating && (<Text field={{ value: labelRating }} />)}
        </div>
      ) : (
        <Text field={label} />
      )}
    </Typography>
  );
};

const RadioMUI = props => {
  const classes = useStyles();
  const {
    data,
    shouldShowLimited,
    seeMoreText,
    seeLessText,
    shouldShowResultsCount,
    className,
    isFilterRating,
    customStyle,
  } = props;
  const { totalResultCount, fieldLabel } = props.data;
  const [shouldShowSeeMore, setState] = React.useState(true);

  const handleSeeMoreClick = () => {
    setState(!shouldShowSeeMore);
  };
  const renderFormControlLabel = (listItems, shouldShowLimited) => {
    if (shouldShowLimited) {
      let radioToShow = [];
      let radioToHide = [];
      for (let index in listItems) {
        let DOM = '';
        const { displayText, key, count } = listItems[index].fields;
        const displayTextToShow = shouldShowResultsCount
          ? { value: `${displayText.value} (${count.value})` }
          : displayText;
        if (parseInt(index) <= 3) {
          DOM = (
            <FormControlLabel
              value={key && key.value}
              control={<StyledRadio />}
              label={renderLabel(displayTextToShow, isFilterRating)}
              key={index}
            />
          );

          radioToShow.push(DOM);
        } else {
          DOM = (
            <FormControlLabel
              value={key && key.value}
              control={<StyledRadio />}
              label={renderLabel(displayTextToShow, isFilterRating)}
              key={index}
            />
          );

          radioToHide.push(DOM);
        }
      }

      return (
        <React.Fragment>
          {radioToShow}
          {!shouldShowSeeMore && radioToHide}
          {radioToHide && radioToHide.length > 0 && (
            <div>
              <span
                className={clsx(
                  classes.toggleIcon,
                  shouldShowSeeMore ? 'more' : 'less'
                )}
                onClick={() => handleSeeMoreClick()}
              >
                {shouldShowSeeMore ? seeMoreText.value : seeLessText.value}
              </span>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      let options = listItems.map((item, index) => {
        const { displayText, key, count } = item.fields;
        const displayTextToShow = shouldShowResultsCount
          ? { value: `${displayText.value} (${count.value})` }
          : displayText;
        return (
          <FormControlLabel
            value={key.value}
            control={<StyledRadio />}
            label={renderLabel(displayTextToShow, isFilterRating)}
            key={index}
          />
        );
      });

      return options;
    }
  };
  const handleChange = event => {
    let target = {};
    target.name = props.data.fieldName.value;
    if (event.target.value) {
      target.value = event.target.value;
    } else {
      target.value = '';
    }

    props.onChange({ target: target });
  };
  return (
    <FormControl
      component="fieldset"
      aria-label={fieldLabel && fieldLabel.value}
      className={clsx(classes.formControl, className, customStyle)}
    >
      <legend />
      {fieldLabel && fieldLabel.value && (
        <FormLabel className={classes.labelHeading} component="legend">
          {fieldLabel &&
            `${fieldLabel.value} ${shouldShowResultsCount ? `(${totalResultCount})` : ''
            }`}
        </FormLabel>
      )}
      <RadioGroup
        aria-label={data.fieldLabel.value}
        value={data.selectedValue.value}
        name={data.fieldName.value}
        onChange={handleChange}
      >
        {renderFormControlLabel(props.data.listItems, shouldShowLimited)}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioMUI;
